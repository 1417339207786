import { createStore } from "vuex";
import bookings from './modules/bookings'
import userRoles from './modules/userRoles'

export default createStore({
  state: {
    //App version
    version: "v1.0.4",
    // Security state
    protectedSections: {
      finance: {
        requiresAuth: false,
        isAuthenticated: false
      },
      gallery: {
        requiresAuth: false,
        isAuthenticated: false
      },
      services: {
        requiresAuth: false,
        isAuthenticated: false
      },
      bookings: {
        requiresAuth: false,
        isAuthenticated: false
      },
      leads: {
        requiresAuth: false,
        isAuthenticated: false
      },
      customers: {
        requiresAuth: false,
        isAuthenticated: false
      },
      inventory: {
        requiresAuth: false,
        isAuthenticated: false
      },
      employees: {
        requiresAuth: false,
        isAuthenticated: false
      }
    },
    referralID: "",
    globalDate: null,
    viewingDate: null,
    radiiCheck: true,
    radiiResult: {
      position: {
        lat: 0,
        lng: 0,
      },
      signupStage: "",
      address: {
        completeAddress: "",
        companyAddress: "",
        companyCity: "",
        companyProvince: "",
        companyCountry: "",
        companyAddress: "",
        companyPostal: "",
      },
    },
    whiteLabelClient: null,
    demoProfile: null,
    toggleShortSlideOverPanel: false,
    retrievedKeyMetricsData: {},
    hasSubscribed: false,
    subscriptionStatus: null,
    //Onboarding
    onboardSteps: 0,
    onboardingAid: { tintSeries: [] },
    id: "",
    //Video Walkthroughs
    videoTitle: "",
    videoLink: "",
    videoOpen: false,
    onboardingOpen: false,
    //Customers and Bookings
    customers: {},
    bookingListOpen: false,
    selectedCustomerBookings: {},
    sendReviewRequestToggled: true,
    bookingViews: {
      serviceTypeSelected: false,
      vehicleTypeSelected: false,
      filmOptionSelected: false,
      installTypeSelected: false,
      timeSlotSelected: false,
      shadeOptionSelected: false,
      bookingComplete: false,
    },

    // Modals
    tintBrandsModal: false,
    //SERVICES
    serviceType: null,
    customServiceName: null,
    customBooking: {
      duration: null,
      price: null,
      bookingNotes: "",
    },
    selectedService: {
      service: "",
      active: false,
    },

    //SMART CARDS
    editCardOpen: false,
    chooseSmartCardOpen: false,
    smartCardList: [],
    selectedSmartCard: {},
    smartCardPaid: false,
    smartCardType: "",
    //////////////////////////////////////

    //TINT SERVICES OPTIONS
    tintOptions: {
      selectorPanelOpen: false,
      shades: [],
      filmType: "",
      filmDescription: "",
      index: null,
      optionsObject: {},
    },
    flatGlassData: {
      config: {
        isOffering: false,
      },
      installPackages: [
        {
          active: false,
        },
        {
          active: false,
        },
        {
          active: false,
        },
      ],
    },
    ppfData: {
      config: { 
        isOffering: false 
      },
      vehicleTypes: [
        { name: "Commuter", active: true },
        { name: "Commercial", active: true },
        { name: "Construction", active: true },
        { name: "Maritime", active: true },
      ],
      installPackages: [
        { name: "Partial Hood", active: true },
        { name: "Full Front", active: true },
        { name: "Partial Vehicle", active: true },
        { name: "Entire Vehicle", active: true },
      ],
    },
    vinylData: {
      config: {
        isOffering: false,
      },
      installPackages: [
        {
          active: false,
        },
        
        {
          active: false,
        },
        
        {
          active: false,
        },
        
        {
          active: false,
        },  
      ],
      vehicleTypes: [
        { name: "Commuter", active: true },
        { name: "Commercial", active: true },
        { name: "Construction", active: true },
        { name: "Maritime", active: true },
      ],
    },
    //CUSTOM PRICES
    myCustomPriceListOpen: false,
    myCustomPriceList: [],
    myCustomPriceInstallPackages: null,
    //Lead View
    leadView: false,
    //BookDemo
    demoDateTimeSelected: null,
    demoCompleted: false,
    // Access Code
    accessCode: "",
    // Adding Manufacturer To Tint Bolt
    manufacturerSeries: {},
    //Warranties
    pendingWarranties: [],
    selectedPendingWarranty: null,
    selectedPendingWarranty_Id: "",
    //Metrics
    keyMetricsData: {},
    previousMetricsData: {},
    // Accounting
    monthView: "",
    projectionsView: true,
    projectedRevenue: {
      bookings: [],
      monthlyTotal: 0,
      previousMonthlyTotal: 0,
      estimatedTotal: 0,
      ascending: false,
    },
    accounting: {
      monthSelected: "",
      yearSelected: "",
      revenue: {},
      projected: {},
      expenses: {
        cos: {
          costOfFilm: {},
          shippingCost: {},
          paymentProcessing: {
            percent: 0,
            fee: 0,
            total: 0,
          },
        },
        opex: {
          default: {
            shopRent: 0,
            phoneCost: 0,
            tax: "0.00",
            tintBoltCost: 0,
          },
          expenseList: [],
          user: {},
        },
      },
      pl: {
        revenue: 0,
        cos: 0,
        opex: 0,
        payProcessing: 0,
        taxes: 0,
        grossProfit: 0,
        grossMargin: 0,
        netProfit: 0,
        netMargin: 0,
        profitMargin: 0,
      },
      personalFinance: {
        expenseList: [],
        extraIncome: {},
        expenses: {},
      },
    },
    userExpenseCreator: {
      fixedVariable: {
        open: false,
      },
      percent: {
        open: false,
      },
    },
    //Theme Options Nav States
    themeOptionsNav: {
      themeColor: true,
      backgroundImages: false,
      visibilityOptions: false,
    },
    modifyHours: false,
    companyHours: null,
    //Data points
    vehicleData: {},
    //Lead Gen
    leadGenList: [],
    flatLeadList: [],
    //Is Subscribed
    isSubscribed: true,
    isTrial: true,
    accountLock: false,
    customer: "",
    blankLoader: false,
    //User Config
    userConfig: {},
    //Forgot Password
    forgotPassword: false,
    //Revoke Modal
    revokeOpen: false,
    //Profile Open
    profileOpen: false,
    usersOpen: false,
    integrationsOpen: false,
    referralsOpen: false,
    embedWidgetOpen: false,
    //Manufacturer States
    manufacturerRequestOpen: false,
    //Dashboard View Control
    dashBoardView: "",
    //ADD VEHICLES FOR SYNC TO LIST
    addedVehicles: [],
    //SERVICE ITEMS PULLED FROM SQUARE
    catalogList: [],
    //VEHICLE TYPES AND STATES
    vehicleTypes: [
      { name: "Sedan", selected: false, serviceId: "#sedan" },
      { name: "Compact SUV", selected: false, serviceId: "#compactSUV" },
      { name: "Coupe", selected: false, serviceId: "#coupe" },
      { name: "2DR Truck", selected: false, serviceId: "#twoTruck" },
      { name: "3DR Hatchback", selected: false, serviceId: "#threeHatch" },
      { name: "4DR Truck", selected: false, serviceId: "#fourTruck" },
      { name: "5DR Hatchback", selected: false, serviceId: "#fiveHatch" },
      { name: "Large SUV", selected: false, serviceId: "#largeSUV" },
      { name: "Minivan", selected: false, serviceId: "#minivan" },
      { name: "Wagon", selected: false, serviceId: "#wagon" },
      { name: "Medium SUV", selected: false, serviceId: "#mediumSUV" },
    ],
    // MODES
    automotiveTintMode: "shop",
    automotiveTintModeDB: null,
    automotiveMobileTravelingFee: 0,
    automotiveMobileTravelingDistance: { distance: 0, unit: 0 },
    //BOOKING MODE
    bookingMode: "shop",
    siteReady: false,
    //ADDED VEHICLE LIST
    addedVehiclesList: [],
    //COMPANY PROFILE OBJECT
    companyProfile: {},
    companyPublicProfile: {},
    contentMatrix: {},
    //GALLERY IMAGES
    galleryList: [],
    //SERVICES READY
    serviceReady: false,
    //Booking View
    viewStep: {
      stepOneOpen: true,
      stepTwoOpen: false,
      stepThreeOpen: false,
    },
    //BOOKING HOURS
    openingHour: 0,
    closingHour: 23,
    //BOOKING DATA
    bookingData: {
      vehicle: "",
      install: "",
      price: "",
      selectedDay: "",
      selectedTime: "",
      year: "",
      model: "",
      rearTint: "",
      frontTint: "",
      offSite: false,
      segment: {},
    },
    //BOOKING UI
    activeDateTime: null,
    openBookingUI: false,
    selectedDay: {
      events: [],
    },
    bookingTier: false,
    bookingServiceType: false,
    bookingServicesSelected: false,
    viewBookingDetails: {},
    viewBooking: false,

    viewOtherBookingDetails: {},
    viewOtherBooking: false,

    fetchedBookings: [],
    fetchedOtherBookings: [],
    activeStates: {
      activeVehicleType: "",
      activeTier: "",
      activeSeries: "",
      activeServiceType: "",
      activeTimeSlot: null,
      activeInstallDate: null,
      activeShades: [],
    },
    selectedInstall: {
      vehicleType: "",
      installType: "",
      series: "",
      time: "",
      selectedDate: {
        day: "",
        month: "",
        year: "",
        monthName: "",
      },
      selectedServicesList: [],
      servicesSelected: [],
    },
    customer: {},
    bookingDetails: {
      customerDetails: {
        name: "",
        phoneNumber: "",
        email: "",
      },
      vehicleDetails: {
        make: "",
        model: "",
        year: "",
        vehicleType: "",
      },
      installDetails: {
        installType: "",
        shades: { front: "", rear: "" },
        price: null,
        startTime: null,
        endTime: null,
      },
    },
    calendarConfig: {
      duration: null,
    },
    availability: [],
    hideCalendar: true,
    availabilityLoading: null,
    tintAvailability: [],
    tintServices: null,
    listBookings: [],
    listLoading: false,
    preListLoad: false,
    //HELP
    helpOpen: false,
    //APPLY FORM
    apply: {
      token: "",
      domain: "",
      analytics: "",
      elfsight: "",
      color: "",
      business: {
        registered: false,
        insured: false,
        licensed: false,
      },
      warranty: {
        standard: "",
        premium: "",
        prestige: "",
      },
    },
    bannerOpen: true,
    currentUser: "",
    clientInfo: {
      client: {},
      subscription: {},
    },
    companyInfo: {},
    infoLoaded: false,
    loggedIn: false,
    loading: false,
    loadingSignup: false,
    currentDashboardView: {
      dashboard: true,
      services: false,
      bookings: false,
      leads: false,
      gallery: false,
      "landing Page": false,
      customers: false,
      finance: false,
      smartCards: false,
      warranties: false,
      options: false,
      inventory: false,
      employees: false
    },
    tintBrands: [],
    selectedBrand: {},
    selectedTier: "",
    checkOptions: [],
    activeOptions: [],
    checkLoader: true,
    userMeta: {
      configComplete: true,
    },
    companyProfileName: "",
    showHideCreator: false,
    warrantySearchResults: {},
    showHideSearches: false,
    viewWarranty: false,
    clientNumber: "",
    clientWarranty: {},
    clientWarrantyId: "",
    openTintCreate: false,
    optionInCreation: {},
    companyName: "",
    shopWarrantyId: {},
    chosenManufacturer: {
      tintBrandName: "",
      seriesList: {},
    },
    //Chosen Brand
    settingFilmQuality: "",
    shadesObj: {},
    tierSettings: {},
    seriesList: "",
    services: [],
    smsSegments: null,
    flatGlassInquiries: {
      total: 0,
      new: 0
    },
    ppfInquiries: {
      total: 0,
      new: 0
    },
    vinylWrapInquiries: {
      total: 0,
      new: 0
    },
    updatePopupRef: null,
    updateSettings: {
      currentVersion: 'v1.0.4',
      hideUpdates: false,
      lastSeenVersion: null,
      isUpdatePopupOpen: false,
      updateData: null
    },
    tintServices: null,
    securitySettings: {
      sections: {},
      sessionTimeout: null,
      hasPassword: false,
      lastFetched: null
    },
    securitySettingsOpen: false,
    tutorialsOpen: false,
    accountingConfig: {
      processingMethods: [],
      salesTaxRate: 0,
      includeProcessingFeeInTotal: true
    },
    isTimeModifierOpen: false,
    vehiclesUpdated: false,
  },
  getters: {
    getViewBookingDetails: (state) => {
      return state.viewBookingDetails;
    },
  },
  mutations: {
    // Define mutations if you need to modify viewBookingDetails
    setViewBookingDetails(state, payload) {
      state.viewBookingDetails = payload;
    },
    SET_MODIFY_HOURS(state, value) {
      state.modifyHours = value;
    },
    updateSendReviewRequestToggled(state, newValue) {
      state.sendReviewRequestToggled = newValue;
    },
    setAutomotiveTintMode(state, mode) {
      state.automotiveTintMode = mode;
    },
    setAutomotiveMobileTravelingFee(state, fee) {
      state.automotiveMobileTravelingFee = fee;
    },
    setAutomotiveMobileTravelingDistance(state, distance) {
      state.automotiveMobileTravelingDistance = distance;
    },
    setAutomotiveTintModeDB(state, mode) {
      state.automotiveTintModeDB = mode;
    },
    setBookingMode(state, mode) {
      state.bookingMode = mode;
    },
    SET_FLAT_GLASS_INQUIRIES(state, { total, new: newCount }) {
      state.flatGlassInquiries.total = total;
      state.flatGlassInquiries.new = newCount;
    },
    SET_PPF_INQUIRIES(state, { total, new: newCount }) {
      state.ppfInquiries.total = total;
      state.ppfInquiries.new = newCount;
    },
    SET_VINYL_INQUIRIES(state, { total, new: newCount }) {
      state.vinylWrapInquiries.total = total;
      state.vinylWrapInquiries.new = newCount;
    },
    SET_UPDATE_POPUP_REF(state, ref) {
      state.updatePopupRef = ref
    },
    SET_HIDE_UPDATES(state, value) {
      state.updateSettings.hideUpdates = value
    },
    SET_LAST_SEEN_VERSION(state, version) {
      state.updateSettings.lastSeenVersion = version
    },
    SET_UPDATE_POPUP_OPEN(state, value) {
      state.updateSettings.isUpdatePopupOpen = value
    },
    SET_UPDATE_DATA(state, data) {
      state.updateSettings.updateData = data
    },
    // Add mutations for security state
    SET_SECTION_REQUIRES_AUTH(state, { section, value }) {
      if (state.protectedSections[section]) {
        state.protectedSections[section].requiresAuth = value;
      }
    },
    SET_SECTION_AUTHENTICATED(state, { section, value }) {
      if (state.protectedSections[section]) {
        state.protectedSections[section].isAuthenticated = value;
      }
    },
    SET_SECURITY_SETTINGS(state, settings) {
      state.securitySettings = {
        ...settings,
        lastFetched: new Date().getTime()
      }
    },
    UPDATE_SECTION_SECURITY(state, { section, requiresPassword }) {
      state.securitySettings.sections = {
        ...state.securitySettings.sections,
        [section.toLowerCase()]: requiresPassword
      }
    },
    SET_SESSION_TIMEOUT(state, timeout) {
      state.securitySettings.sessionTimeout = timeout
    },
    SET_HAS_PASSWORD(state, hasPassword) {
      state.securitySettings.hasPassword = hasPassword
    },
    SET_SECURITY_SETTINGS_OPEN(state, value) {
      state.securitySettingsOpen = value;
    },
    SET_TUTORIALS_OPEN(state, value) {
      state.tutorialsOpen = value;
    },
    SET_TIME_MODIFIER_OPEN(state, value) {
      state.isTimeModifierOpen = value;
    },
    SET_VEHICLES_UPDATED(state, value) {
      state.vehiclesUpdated = value;
      // Reset after a short delay to allow components to detect the change
      if (value) {
        setTimeout(() => {
          state.vehiclesUpdated = false;
        }, 1000);
      }
    },
  },
  actions: {},
  modules: {
    bookings,
    userRoles
  }
});
