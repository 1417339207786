import { projectFirestore } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

// For testing purposes (remove for production)
const DEBUG_MODE = true;
const FORCE_MODERATOR = true; // Set to false in production

const state = {
  userRole: null,
  isModerator: false,
  isLoading: false,
  error: null
};

const getters = {
  isModerator: (state) => state.isModerator,
  userRole: (state) => state.userRole
};

const actions = {
  async fetchUserRole({ commit, rootState }) {
    if (!rootState.currentUser) {
      console.log('[UserRoles] No current user found');
      return;
    }
    
    commit('SET_LOADING', true);
    try {
      console.log('[UserRoles] Fetching role for user:', rootState.currentUser.uid);
      const userRef = doc(projectFirestore, 'users', rootState.currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const role = userData.role || 'user';
        const isModeratorRole = (role === 'admin' || role === 'moderator');
        
        console.log('[UserRoles] User role from DB:', role);
        console.log('[UserRoles] Is moderator from DB:', isModeratorRole);
        
        // Apply debug overrides if enabled
        const finalModeratorStatus = DEBUG_MODE && FORCE_MODERATOR ? true : isModeratorRole;
        
        commit('SET_USER_ROLE', role);
        commit('SET_IS_MODERATOR', finalModeratorStatus);
        
        console.log('[UserRoles] Final moderator status:', finalModeratorStatus);
      } else {
        console.log('[UserRoles] User document not found');
        commit('SET_USER_ROLE', 'user');
        commit('SET_IS_MODERATOR', DEBUG_MODE && FORCE_MODERATOR);
      }
      commit('SET_ERROR', null);
    } catch (error) {
      console.error('[UserRoles] Error fetching user role:', error);
      commit('SET_ERROR', error.message);
      commit('SET_USER_ROLE', 'user');
      commit('SET_IS_MODERATOR', DEBUG_MODE && FORCE_MODERATOR);
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

const mutations = {
  SET_LOADING(state, status) {
    state.isLoading = status;
  },
  SET_USER_ROLE(state, role) {
    state.userRole = role;
  },
  SET_IS_MODERATOR(state, status) {
    state.isModerator = status;
    console.log('[UserRoles] isModerator state set to:', status);
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}; 