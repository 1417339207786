import { createRouter, createWebHistory } from "vue-router";
import { projectAuth } from "../firebase/config";
import store from "../store";

// auth guard

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/newLogin.vue"),
  },
  {
    path: "/account-validation",
    name: "AccountValidation",
    component: () =>
      import(/* webpackChunkName: "account-validation" */ "../components/AccountValidation.vue"),
    meta: { requiresAuth: true },
    beforeEnter: requireAuth,
  },
  {
    path: "/square-callback",
    name: "SquareCallback",
    component: () =>
      import(/* webpackChunkName: "square-callback" */ "../views/SquareCallback.vue"),
    meta: { requiresAuth: true },
    beforeEnter: requireAuth,
  },
  {
    path: "/book-demo",
    name: "BookDemo",
    component: () =>
      import(/* webpackChunkName: "book-demo" */ "../views/BookDemo.vue"),
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      const user = projectAuth.currentUser;
      if (!user) {
        next({ name: "Login" });
        return;
      }

      // Check if user has already completed configuration
      if (store.state.userConfig?.configComplete) {
        next({ name: "Dashboard" });
        return;
      }

      next();
    }
  },
  {
    path: "/affiliate-program",
    name: "Affiliates",
    component: () =>
      import(/* webpackChunkName: "affiliates" */ "../views/Affiliates.vue"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
  },
  {
    path: "/apply",
    name: "Apply",
    component: () =>
      import(/* webpackChunkName: "apply" */ "../views/Apply.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/tos",
    name: "TOS",
    component: () => import(/* webpackChunkName: "tos" */ "../views/TOS.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = store.state.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if ((requiresAuth || to.name === 'BookDemo') && !currentUser) {
    next({ name: 'Login' });
  } else if (!requiresAuth && currentUser && to.name === 'Login') {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;
