import { 
  collection, 
  getDocs,
  doc,
  query,
  where 
} from 'firebase/firestore'
import { projectFirestore } from '@/firebase/config'
import { getBookings } from '@/composables/getBookings'

export default {
  namespaced: true,
  
  state: {
    fetchedBookings: [],
    viewBookingDetails: {},
    viewBooking: false,
    viewOtherBookingDetails: {},
    viewOtherBooking: false,
  },

  mutations: {
    setFetchedBookings(state, bookings) {
      state.fetchedBookings = bookings
    },
    setViewBookingDetails(state, details) {
      state.viewBookingDetails = details
    },
    setViewBooking(state, value) {
      state.viewBooking = value
    }
  },

  actions: {
    async fetchBookings({ commit, rootState }, { month, year }) {
      try {
        const { bookings, otherBookings } = await getBookings(month, year)
        
        // Transform the bookings into the format we need
        const transformedBookings = Object.entries(bookings).map(([id, booking]) => ({
          id,
          ...booking
        }))

        commit('setFetchedBookings', transformedBookings)
        return transformedBookings
      } catch (error) {
        console.error('Error fetching bookings:', error)
        throw error
      }
    }
  }
} 